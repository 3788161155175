<template>
	<div class="http">
		<el-dialog v-model="data.dialogVisible" :before-close="handleClose" :close-on-click-modal="false" center width="30%">
			<div class="content">
				<div>
					<img v-if="!data.state" alt="" src="@/assets/images/http-state/network_error.png" />
					<img v-else alt="" src="@/assets/images/http-state/icon_info.png" />
				</div>
				<div class="title" style="word-break: break-word" v-html="data.msg"></div>
			</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button type="primary" @click="handleClose">知道了</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>

<script setup>
import { useStore } from "vuex";
import { computed } from "vue";

const store = useStore();
const data = computed(() => store.state.http.httpData);
const handleClose = () => {
	store.dispatch("http/resetHttpState");
};
</script>

<style lang="scss" scoped>
.http {
	:deep(.el-dialog) {
		border-radius: 14px;
		padding-bottom: 20px;
		// width: 289px;
		// height: 186px;
		background: #ffffff;
		border-radius: 11px 11px 11px 11px;
		opacity: 1;

		.content {
			text-align: center;
			font-size: 22px;
			color: #ffffff;

			img {
				width: 129px;
				height: auto;
				padding-bottom: 20px;
			}

			.title {
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #1f2322;
				-webkit-background-clip: text;
				-webkit-text-fill-color: #1f2322;
			}
		}

		.dialog-footer {
			.el-button {
				width: 120px;
				background: linear-gradient(274deg, #1c201f 0%, #242827 45%, #2e3131 100%);
				border: none;
			}
		}
	}
}
</style>
