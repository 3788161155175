import http from "@/utils/request.ts";

// 获取用户资料
export const checkToken = () => {
	return http({ path: `/web/users/checkToken`, method: "get" });
};
// 获取用户资料
export const getProfile = (data) => {
	return http({ path: `/web/users/profile`, method: "get", params: data });
};

// 获取二维码
export const getQrcode = (data) => {
	return http({ path: `/web/dc/user/wx/code`, method: "post", params: data });
};
// 获取二维码
export const getScanResult = (data) => {
	return http({ path: `/web/users/checkWechat`, method: "get", params: data });
};

// 获取账户明细
export const getAccountDetail = (data) => {
	return http({ path: `/web/users/consume`, method: "get", params: data });
};

// 修改用户信息
export const updateProfile = (data) => {
	return http({ path: `/web/dcUser/setUserInfo`, method: "post", params: data });
};

// 钻石转金币
export const diamondToGold = (data) => {
	return http({ path: `/web/withdrawl/toCoin`, method: "post", params: data });
};
// 取消充值
export const cancelRecharge = (data) => {
	return http({ path: `/web/withdrawl/cancel`, method: "post", params: data });
};

// 充值
export const recharge = (data) => {
	return http({ path: `/web/dcUser/pay`, method: "post", params: data });
};

export const chargeCapture = (data) => {
	return http({ path: `/web/dcUser/charge/capture`, method: "get", params: data });
};

// 查询充值状态
export const getRechargeStatus = (data) => {
	return http({ path: `/web/dcUser/charge/check`, method: "get", params: data });
};

// 提现
export const withdraw = (data) => {
	return http({ path: `/web/withdrawl/createWebOrder`, method: "post", params: data });
};

// 获取充值列表
export const getRechargeList = (data) => {
	return http({ path: `/web/dcUser/charge/rule`, method: "get", params: data });
};

// 获取老板排行
export const getBossRank = (data) => {
	return http({ path: `/web/dcUser/top/user`, method: "get", params: data });
};
// 获取出道榜
export const getActivityRank = (data) => {
	return http({ path: `/web/dc/v1/activity/ranking`, method: "get", params: data });
};

// 使用助力券
export const useAssist = (data) => {
	return http({ path: `/web/dc/v1/activity/product/user/add`, method: "post", params: data });
};

// 获取陪玩排行
export const getPlayRank = (data) => {
	return http({ path: `/web/dcUser/top/players`, method: "get", params: data });
};

// 获取礼物列表
export const getGiftList = (data) => {
	return http({ path: `/web/dc/gift/user/list`, method: "get", params: data });
};

// 上一周&上一月排行榜
export const queryLastWeekMonthList = (data) => {
	return http({ path: `/web/discord/historyRanking/queryLastWeekMonthList`, method: "get", params: data });
};
// 陪玩扣单排行
export const getCallRank = (data) => {
	return http({ path: `/web/dcUser/top/call`, method: "get", params: data });
};

/**
 * 上传
 */
export const upload = (params) => http({ path: `/web/sidekick/common/upload`, method: "post", params });

export const getCountryList = (params) => http({ path: `/web/dcUser/getCountries`, method: "get" });

export const clearOpenid = (params) => http({ path: `/web/dcUser/del/openid?dcid=${params.dcid}`, method: "post" });

/**
 * 成就总览
 */
export const achieveOverview = (params) => http({ path: `/web/dc/achieve/overview`, method: "get", params });
/**
 * 陪玩成就
 */
export const achievePlay = (params) => http({ path: `/web/dc/achieve/play`, method: "get", params });
/**
 * 老板成就
 */
export const achieveBoss = (params) => http({ path: `/web/dc/achieve/boss`, method: "get", params });
/**
 * 礼物成就
 */
export const achieveGift = (params) => http({ path: `/web/dc/achieve/gift`, method: "get", params });
/**
 * 限定成就
 */
export const achieveLimit = (params) => http({ path: `/web/dc/achieve/limited`, method: "get", params });
/**
 * 限时成就
 */
export const achieveLimited = (params) => http({ path: `/web/dc/achieve/limit/month`, method: "get", params });
/** 限时成就查询 */
export const getLimitedType = (params) =>
	http({
		path: `/web/dc/achieve/month/type`,
		method: "get",
		params,
	});
/**
 * 成就等级领取接口
 */
export const giftReceiveAward = (params) => http({ path: `/web/dc/achieve/giftReceiveAward`, method: "post", params });
/**
 * 成就总览领取接口
 */
export const getGeneralCatalogue = (params) =>
	http({
		path: `/web/dc/achieve/getGeneralCatalogue`,
		method: "get",
		params,
	});
/** 红包列表 */
export const getRedPacketList = (params) => http({ path: `/web/dcRedPacket/list`, method: "get", params });
/** 红包详情 */
export const getRedPacketDetail = (id) => http({ path: `/web/dcRedPacket/${id}`, method: "get" });

/**
 * 我的邀请
 */
export const myInvent = (params) => http({ path: `/web/dcUser/myInvent`, method: "get", params });
/**
 * 我的邀请-领取
 */
export const inventReward = (params) => http({ path: `/web/dcUser/inventReward`, method: "get", params });

/**
 * 我的优惠券
 */
export const myVouchers = (params) => http({ path: `/web/dcUser/myVouchers`, method: "get", params });
/** 购买优惠券 */
export const buyVouchers = (params) => http({ path: `/web/dc/v1/activity/product/user/pay`, method: "post", params });
/** 查询用户背包助力券 */
export const getAssistList = (params) => http({ path: `/web/dc/v1/activity/product/user/info`, method: "get", params });

export const buyNitro = (params) => http({ path: `/web/nitro/buyNitro`, method: "post", params });
// 库存
export const nitroStock = (params) => http({ path: `/web/nitro/nitroStock`, method: "get", params });
// 查询有效的改名记录
export const renameList = (params) => http({ path: `/web/dc/rename/list`, method: "get", params });
// 更新改名记录顺序
export const renameUpdate = (params) => http({ path: `/web/dc/rename/update`, method: "post", params });

// 获取邀请奖励列表
export const getInviteRewardList = (params) =>
	http({
		path: `/web/discord/userInvite/queryList`,
		method: "get",
		params,
	});

// 领取邀请奖励
export const getInviteReward = (params) =>
	http({
		path: `/web/discord/userInvite/receiptInAdvance`,
		method: "get",
		params,
	});

/** 成就待领取 */
export const getAchieveNum = (params) => http({ path: `/web/dc/achieve/num`, method: "get", params });

/** 租赁 账号列表 */
export const rentList = (params) => http({ path: `/web/rent/rentList`, method: "get", params });

/** 租赁 我的租赁列表 */
export const myRent = (params) => http({ path: `/web/rent/myRent`, method: "get", params });

/** 租赁 租用账号 */
export const rentAccount = (params) => http({ path: `/web/rent/rentAccount`, method: "post", params });

/** 租赁 游戏列表 */
export const rentFilter = (params) => http({ path: `/web/rent/rentFilter`, method: "get", params });

// 已选择的摇号
export const getSelectedLottery = (params) => http({ path: `/web/dc/beautiful/info`, method: "get", params });
// 取消摇号
export const cancelLottery = (params) => http({ path: `/web/dc/beautiful/burial`, method: "post" });
// 待选择的号
export const getLotteryList = (params) => http({ path: `/web/dc/beautiful/list`, method: "get", params });
// 摇号
export const lottery = (params) => http({ path: `/web/dc/beautiful/lottery`, method: "post", params });
// 确认摇号
export const lotteryConfirm = (params) => http({ path: `/web/dc/beautiful/binding`, method: "post", params });
// 摇号是否可用
export const lotteryAvailable = (params) => http({ path: `/web/dc/beautiful/whether/use`, method: "get", params });
