import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "./assets/css/com.scss";
import "./assets/css/share.scss";
import HttpState from "@/components/http/http.vue";
import "flag-icon-css/css/flag-icons.css";

import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import moment from "moment-timezone";

moment.tz.setDefault("Europe/London");

const app = createApp(App);

app.use(ElementPlus, {
	locale: zhCn,
});

app.component("HttpState", HttpState);

app.use(store).use(router).use(ElementPlus).mount("#app");
