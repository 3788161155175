import * as types from "./mutation_types";

export const setHttpState = (store: any, data: object) => {
	console.log(data);
	store.commit(types.HTTP_DATA, data);
};
export const resetHttpState = (store: any) => {
	store.commit(types.REACT_DATA);
};
export const setLoginState = (store: any, data: boolean) => {
	store.commit(types.LOGIN_STATE, data);
};
