<!--
 * @Author: zangqisong
 * @Date: 2023-11-16 20:26:19
 * @LastEditors: zangqisong
 * @LastEditTime: 2024-05-19 19:51:15
 * @Description:
-->
<template>
	<AppHeader />
	<div class="app-content">
		<router-view />
	</div>
	<http-state></http-state>
</template>

<script setup>
import AppHeader from "@/components/AppHeader.vue";
import { onMounted, provide, ref } from "vue";
import { getQueryVariable } from "@/utils/utils";
import { checkToken, getAchieveNum, getProfile } from "@/api/profile";

const achieveNum = ref("");
const profileData = ref({});
provide("achieveNum", achieveNum);
provide("profileData", profileData);
onMounted(() => {
	let dcid = getQueryVariable().dcid;

	// 判断是否是登录页面
	if (window.location.href.indexOf("login") > -1 || window.location.href.indexOf("403") > -1) {
		return;
	}

	// 判断是否是手机端
	if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
		window.location.href = "https://m.ceiling.gg/profile?dcid=" + dcid;
	}
	if (dcid) {
		localStorage.setItem("Authorization", dcid);

		checkToken().then((res) => {
			if (res) {
				localStorage.setItem("dcid", res.msg);
				getProfile({ dcid: res.msg }).then((res) => {
					if (res) {
						profileData.value = res;
						localStorage.setItem("profileData", JSON.stringify(res));
					}
				});
				getAchieveNum({ discordId: res.msg }).then((res) => {
					if (res) {
						achieveNum.value = res;
					}
				});
			}
		});
	} else {
		if (localStorage.getItem("profileData")) {
			profileData.value = JSON.parse(localStorage.getItem("profileData"));
			getAchieveNum({ discordId: localStorage.getItem("dcid") }).then((res) => {
				if (res) {
					achieveNum.value = res;
				}
			});
		}
	}

	if (getQueryVariable().action === "event") {
		document.title = "Celling";
	} else {
		document.title = "赛琳欧服陪玩";
	}
});
</script>

<style lang="scss">
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #1c201f;
	background: #080808;
}

.el-loading-spinner {
	transform: translateY(-50vh);
}

.app-content {
	padding-top: 130px;
	overflow: auto;
	height: calc(100vh - 130px);
}

#loading {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 9999;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.7);

	img {
		position: absolute;
		width: 300px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.el-image-viewer__img {
	height: 70vh;
	width: auto;
}

input::-webkit-input-placeholder {
	color: #ffffff;
}
input::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	color: #ffffff;
}
input:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	color: #ffffff;
}
input:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #ffffff;
}
</style>
