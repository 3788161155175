<template>
	<div class="sticky">
		<a class="home" href="/home">
			<img alt="" src="@/assets/images/loading.gif" />
		</a>
		<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" router>
			<el-menu-item index="home">首页</el-menu-item>
			<el-menu-item index="like">我的喜欢</el-menu-item>
			<el-menu-item index="entry">入职陪玩</el-menu-item>
			<el-menu-item index="discord" @click="linkToDis">Discord下单</el-menu-item>

			<div class="search">
				<el-input v-model="keyword" class="search-input" placeholder="请输入昵称/ID" @keyup.enter="handleSearch"> </el-input>
			</div>
		</el-menu>

		<div class="right-user flex-e-c">
			<img alt="" class="user-img" src="@/assets/images/discord.png" style="margin-right: 10px" @click="linkToDis" />
			<img v-if="profileData.avatar" :src="profileData.avatar" alt="" class="user-img" @click="linkToUser" />
			<div v-else class="user-btn" @click="linkToUser">登录</div>
		</div>
	</div>
</template>
<script setup>
import { inject, ref } from "vue"; // const profileData = inject("profileData");

const profileData = inject("profileData");

const activeIndex = ref(location.pathname.split("/")[1]);

const linkToDis = () => {
	window.open("https://discord.gg/ceilingpeiwan");
};

const linkToUser = () => {
	window.location.href = "/profile";
};

const keyword = ref("");
const handleSearch = () => {
	if (keyword.value) {
		window.location.href = `/search?keyword=${keyword.value}`;
	}
};
</script>
<style lang="scss" scoped>
.sticky {
	position: fixed;
	top: 0;
	z-index: 40;
	height: 70px;
	width: 100%;
	background: linear-gradient(89deg, #20202b 0%, #20202b 100%);

	.bg {
		width: 100%;
		height: auto;
	}

	.home {
		position: absolute;
		top: 0;
		left: 0;
		width: 302px;
		height: 130px;
		background: url("@/assets/images/head/pic_bg.png") no-repeat 100% 100%;

		img {
			width: 120px;
			position: absolute;
			top: -10px;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	:deep(.el-menu) {
		position: absolute;
		height: 64px;
		top: 0;
		left: 335px;
		background: none;
		width: 100%;
		border: none;

		.is-active {
			border: none;
			color: #35d4f6 !important;
			position: relative;

			&::after {
				position: absolute;
				display: block;
				content: "";
				width: 100%;
				height: 4px;
				background: linear-gradient(90deg, #35d4f6 0%, #f21ec0 100%);
				bottom: 0;
				left: 0;
			}
		}

		.el-menu-item {
			color: #ffffff;
			font-size: 16px;
			margin: 0 10px;

			&:hover,
			&:focus {
				background: none;
				color: #39d3f7;
			}
		}
		.search {
			margin-left: 60px;
			width: 320px;
			.search-input {
				width: 100%;
				.el-input__wrapper {
					border-radius: 30px;
					background: none;
					padding: 4px 20px;
					.el-input__inner {
						color: #ffffff;
					}
				}
			}
			position: relative;
			top: 14px;
		}
	}

	.right-user {
		position: absolute;
		right: 20px;
		top: 10px;

		.user-img {
			width: 44px;
			height: 44px;
			cursor: pointer;
			border-radius: 50%;
			background: #efefef;
		}

		.user-btn {
			width: 90px;
			height: 40px;
			background: linear-gradient(93deg, #35d4f6 0%, #f21ec0 100%);
			border-radius: 60px 60px 60px 60px;
			text-align: center;
			line-height: 40px;
			color: #ffffff;
			font-size: 14px;
			cursor: pointer;
		}
	}
}
</style>
