import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		redirect: "/home",
	},
	{
		path: "/index",
		name: "index",
		component: () => import("@/views/index/index.vue"),
		redirect: "/profile",
		children: [
			{
				path: "/profile",
				name: "profile",
				component: () => import("@/views/profile/profile.vue"),
			},
			{
				path: "/account",
				name: "account",
				component: () => import("@/views/account/account.vue"),
			},
			{
				path: "/order",
				name: "order",
				component: () => import("@/views/order/order.vue"),
			},
			{
				path: "/activity",
				name: "activity",
				component: () => import("@/views/activity/activity.vue"),
			},
			{
				path: "/setting",
				name: "setting",
				component: () => import("@/views/setting/setting.vue"),
			},
			// {
			// 	path: "/register",
			// 	name: "register",
			// 	component: () => import("@/views/sidekick/register/register.vue"),
			// },
			{
				path: "/entry",
				name: "entry",
				component: () => import("@/pages/entry/entry.vue"),
			},
			{
				path: "/gift",
				name: "gift",
				component: () => import("@/views/gift/index.vue"),
			},
			{
				path: "/welfare",
				name: "Welfare",
				component: () => import("@/views/welfare/index.vue"),
			},
			{
				path: "/bill",
				name: "Bill",
				component: () => import("@/views/bill/index.vue"),
			},
			{
				path: "/VIPLevel",
				name: "VIPLevel",
				component: () => import("@/views/system/VIPLevel.vue"),
			},
			{
				path: "/playLevel",
				name: "playLevel",
				component: () => import("@/views/system/playLevel.vue"),
			},
			{
				path: "/block",
				name: "block",
				component: () => import("@/views/block/index.vue"),
			},
			{
				path: "/topUser",
				name: "topUser",
				component: () => import("@/views/profile/topUser.vue"),
			},
			{
				path: "/achieve",
				name: "achieve",
				component: () => import("@/views/achieve/index.vue"),
			},
			{
				path: "/myInvent",
				name: "myInvent",
				component: () => import("@/views/myInvent/index.vue"),
			},
			{
				path: "/myVouchers",
				name: "myVouchers",
				component: () => import("@/views/myVouchers/index.vue"),
			},
			{
				path: "/nitro",
				name: "nitro",
				component: () => import("@/views/nitro/index.vue"),
			},
			{
				path: "/event",
				name: "event",
				component: () => import("@/views/event/index.vue"),
			},
			{
				path: "/eventDetail",
				name: "eventDetail",
				component: () => import("@/views/event/detail.vue"),
			},
			{
				path: "/rentAccount",
				name: "rentAccount",
				component: () => import("@/views/rentAccount/index.vue"),
			},
		],
	},
	{
		path: "/home",
		name: "home",
		component: () => import("@/pages/home/home.vue"),
	},
	{
		path: "/like",
		name: "like",
		component: () => import("@/pages/like/like.vue"),
	},
	{
		path: "/discord",
		name: "discord",
		component: () => import("@/pages/discord/discord.vue"),
	},
	{
		path: "/match",
		name: "match",
		component: () => import("@/views/match/detail.vue"),
	},
	{
		path: "/login",
		name: "login",
		component: () => import("@/views/login/index.vue"),
	},
	{
		path: "/search",
		name: "search",
		component: () => import("@/pages/search/search.vue"),
	},
	{
		path: "/403",
		name: "403",
		component: () => import("@/views/403/403.vue"),
	},
	{
		path: "/discord-upload-user",
		name: "discord-upload-user",
		component: () => import("@/views/discord-upload-user/discord-upload-user.vue"),
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
